.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

body {
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face{
  font-family: Karla;
  src: url(./fonts/Karla/static/Karla-Regular.ttf)
}

.Karla{
  font-family: Karla;
}

@font-face{
  font-family: Montserrat;
  src: url(./fonts/Montserrat/static/Montserrat-Regular.ttf)
}

.Montserrat{
  font-family: Montserrat;
}

@font-face{
  font-family: Bolder-Montserrat;
  src: url(./fonts/Montserrat/static/Montserrat-ExtraBold.ttf)
}

.Bolder-Montserrat{
  font-family: Bolder-Montserrat;
}

@font-face{
  font-family:Bold-Montserrat;
  src: url(./fonts/Montserrat/static/Montserrat-Bold.ttf)
}

.Bold-Montserrat{
  font-family: Bold-Montserrat
}

@font-face{
  font-family: Bold-Karla;
  src: url(./fonts/Karla/static/Karla-Bold.ttf)
}

.Bold-Karla{
  font-family: Bold-Karla
}

.index-div{
  height:auto;
  width:100%;
}

.index-nav{
  width:100%;
}

.logo {
  position:absolute;
  left: 4%;
  top: 4%;
}

.index-buttons {
  position:absolute;
  right: 2%;
  top: 4%;
  width:412px;
  height:50px;
  display:flex;
  justify-content: space-between;
}

/* need to adjust sizing */

.whitelist{
  border-radius: 100px;
  height:52px;
  width:180px;
  border: 2px solid rgba(255,178,88,0.9);
  background-color: transparent;
  color:#FFB240;
  font-family: Karla;
  font-weight:bold;
  font-size:20px;
  display:flex;
  justify-content: center;
  align-items:center;
  text-decoration:none;
}

.whitelist:hover{
  background-color: black;
  border: 2px solid white;
  color:white;
}

.whitelist-nav{
  border-radius: 100px;
  height:52px;
  width:180px;
  border: 2px solid rgba(255,178,88,0.9);
  background-color: transparent;
  color:#FFB240;
  font-family: Karla;
  font-weight:bold;
  font-size:20px;
  display:flex;
  justify-content: center;
  align-items:center;
  text-decoration:none;
}

.whitelist-nav:hover{
  background-color: black;
  border: 2px solid white;
  color:white;
}



.launchapp{
  border-radius: 100px;
  height:52px;
  width:180px;
  border:none;
  background: linear-gradient(180deg, #FFB240 -20%, #FF6A4F 241%);
  color: black;
  font-family: Karla;
  font-weight:bold;
  font-size:20px;
}

.launchapp:hover{
  background: white;
}



.index-header{
  font-size: 72px;
  font-family: Bolder-Montserrat;
  letter-spacing:0.06em;
  line-height:58px;
  text-shadow: 0px 0px 6px rgba(255,106,79,0.6);
  text-transform: uppercase;
	background: linear-gradient(to top, #FF4E6E 0%, #FFB240 50%);
  background-clip:none;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.index-sub{
  font-size: 32px;
  font-family: Montserrat;
  color:white;
  line-height: 52px;
  letter-spacing: 0.02em;
  text-shadow: 0px 0px 6px rgba(255,106,79,0.6);
  font-weight: bolder;
}

.index-text{
  font-size:20px;
  font-family: Karla;
  line-height: 34px;
  color:white;
  position:absolute;
  left:7.5%;
  top:25%;
  height: 300px;
  width: 823px;
}

.index-btns{
  position: absolute;
  top: 60%;
  left:7.5%;
  height: 50px;
  width: 644px;
  display:flex;
  justify-content: space-between;
}

.early-whitelist{
  border-radius: 100px;
  height:52px;
  width:220px;
  border: 2px solid #FFB240;
  background: linear-gradient(180deg, #FFB240 -20%, #FF6A4F 241%);
  background-color:transparent;
  border:none;
  color: black;
  font-family: Karla;
  font-weight:bold;
  font-size:20px;
  display:flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.early-whitelist:hover{
  background:white;
  color:black;
  text-decoration:none;
}

.index-statistics{
  position: absolute;
  top: 74%;
  left: 7.5%;
  width:1144px;
  height:135px;
  display:flex;
  justify-content:space-between;
}

.statistics-div{
  height:100%;
  width: 546px;
  background-color: transparent;
  border: 2px solid rgba(255,178,64,0.2);
  border-radius:12px;
  display:flex;
  justify-content:center;
  background-color:rgba(27,31,31,0.5);
  backdrop-filter:blur(5px);
  z-index:100px;
}

.statistic-box{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  position: relative;
  top: 19px;
  width: 481px;
}

.statistic-head{
  color: #ffb240;
  font-family: Karla;
  font-size: 24px;
  left:0%;
  line-height: 28px;
  margin: 18px;
  position: absolute;
  top: -20%;
}

.statistic-body{
  font-family: Karla;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: 0.04em;
  margin-top:40px;
  color: white;
  text-shadow: 0px 0px 6px rgba(255,188,88,0.40)
}

.circle-div{
  position:absolute;
  top:135%;
  right: 10%;
  height:660px;
  width:660px;
  display:flex;
  justify-content: center;
  align-items:center;
}

.coin-stats-div-top{
  width:360px;
  height:526px;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  position:absolute;
  top:145%;
  left:10%;
}

.coin-stats-div-bot{
  width:360px;
  height:526px;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  position:absolute;
  top:270%;
  right:10%;
}

.coin-div{
  width:90%;
  height:17%;
  border: 2px solid rgba(255,178,64,0.2);
  border-radius: 12px;
  color:white;
  font-family:Bold-Karla;
  letter-spacing:0.02em;
  font-size:48px;
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight:bolder;
  text-shadow: 0px 0px 6px rgba(255,188,88,0.40);
  background-color:rgba(27,31,31,0.5);
  backdrop-filter:blur(5px);
}

.coin-text{
  font-family:Bold-Karla;
  letter-spacing:0.02em;
  font-size:45px;
  font-weight:bolder;
  text-shadow: 0px 0px 6px rgba(255,188,88,0.40);
  color:white;
}

.coin-img{
  position:relative;
  left:-12%;
  width:17%;
  height:auto;
}

.background-img{
  width:17%;
  height:auto;
  z-index:-10;
}

.footer{
  width:100%;
  height:132px;
  background-color:rgba(13,13,14,100%);
  display:flex;
  align-items:center;
}

.index-footer{
  width:100%;
  height:132px;
  background-color:rgba(13,13,14,100%);
  display:flex;
  align-items:center;
}

.featured-div{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 392px;
  justify-content: center;
  position: absolute;
  top: 260%;
  width: 1084px;
}

.featured-head{
  color:white;
  font-family:Bold-Montserrat;
  font-size:60px;
  text-shadow: 0px 0px 4px rgba(255,106,79,0.3)
}

.featured-header-text{
  font-size:24px;
  font-family:Bold-Karla
}

.featured-text{
  font-size:20px;
  font-family:Karla;
}

.featured-info{
  display: flex;
  height: 148px;
  justify-content: space-between;
  position: relative;
  text-align: center;
  top: 15%;
  width: 100%;
}

.featured-gst{
  width:100%;
  color:white;
  border: 2px solid rgba(255,106,79,0.1);
  border-radius:16px;
  display:flex;
  justify-content: center;
  flex-direction:column;
  background-color:rgba(30,30,36,0.6);
  margin-right:32px;
  backdrop-filter:blur(5px);
}

.featured-vault{
  width:452px;
  color:white;
  border: 2px solid rgba(255,106,79,0.1);
  border-radius:16px;
  display:flex;
  justify-content: center;
  flex-direction:column;
  background-color:rgba(30,30,36,0.6);
  backdrop-filter:blur(5px);
}

.featured-aspect{
  display: flex;
  height: 100px;
  justify-content: space-between;
  position: relative;
  top: 25%;
  width:100%;
}



.aspect-element-yield{
  height:100%;
  width:250px;
  background-color:rgba(30,30,36,0.7);
  color:white;
  border: 2px solid rgba(255,106,79,0.1);
  font-size:24px;
  border-radius:16px;
  margin-right:32px;
  padding:32px;
  display: flex;
  justify-content: center;
  backdrop-filter:blur(5px);
}

.aspect-element-comp-yield{
  height:100%;
  width:420px;
  background-color:rgba(30,30,36,0.7);
  color:white;
  border: 2px solid rgba(255,106,79,0.1);
  font-size:24px;
  border-radius:16px;
  margin-right:32px;
  padding:32px;
  display: flex;
  justify-content: center;
  backdrop-filter:blur(5px);
}

.aspect-element-liquidation{
  height:100%;
  width:350px;
  background-color:rgba(30,30,36,0.7);
  color:white;
  border: 2px solid rgba(255,106,79,0.1);
  font-size:24px;
  border-radius:16px;
  margin-right:32px;
  padding:32px;
  display: flex;
  justify-content: center;
  backdrop-filter:blur(5px);
}


.aspect-text{
  width:100%;
  font-size:24px;
  font-family:Karla;
  position:relative;
  top:7%;
  text-shadow:0px 0px 4px rgba(255,178,64,0.3);
}

.aspect-img{
  width:30%;
  height:auto;
}

.eco{
  position:absolute;
  top:360%;
  width:100%;
  display:flex;
  justify-content: center;
}

.eco-div{
  width:1200px;
  height:335px;
  display:flex;
  justify-content: center;
  text-align:center;
  flex-direction: column;
  align-items:center;
}

.space-between-center{
  display:flex;
  justify-content:spcae-between
}

.eco-coins{
  position:relative;
  top:8.5%;
  width:100%;
  height:180px;
  border-radius:200px;
  border: 2px solid rgba(255,106,79,0.1);
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(27,27,31,0.5)
}

.eco-title{
  font-family:Bold-Montserrat;
  font-size:60px;
  text-shadow: 0px 0px 4px rgba(255,178,64,0.3);
  color:white;
}

.eco-info{
  position:relative;
  top:8%;
  font-family:Karla;
  font-size:30px;
  color:white;
  margin-right:20px;
}

.footer-icons{
  position:absolute;
  right:10%;
  display:flex;
  justify-content:space-between;
  align-items: center;
  height:30px;
  width:550px;
}

.footer-logo{
  position:relative;
  width:161px;
  height:59px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  left:10%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* set the rotation to 0 degrees at the beginning of the animation */
  }
  100% {
    transform: rotate(360deg); /* set the rotation to 360 degrees at the end of the animation */
  }
}

@keyframes back-spin {
  0% {
    transform: rotate(360deg); /* set the rotation to 0 degrees at the beginning of the animation */
  }
  100% {
    transform: rotate(0deg); /* set the rotation to 360 degrees at the end of the animation */
  }
}

.animated-div{
  position:absolute;
  animation:spin 20s linear infinite;
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}

.animated-small-div{
  display:flex;
  justify-content: center;
  align-items: center;
  animation:back-spin 40s linear infinite;
  height:100%;
  width:100%;
}


.spinning-item{
  animation: spin 20s linear infinite;
  display:flex;
  justify-content: center;
  align-items: center;
}


.white-logo-div{
  display:flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  width:100%;
  height:100%;
}
.presale-div{
  height:110vh;
  width:100vw;
  overflow-y:hidden;
}

.main-navbar{
  position:relative;
  top:0%;
  height: 104px;
  width:100%;
  display:flex;
  align-items: center;
  justify-content:center;
}

.main-navbar-content{
  width:91.11%;
  height:50px;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.main-nav-logo-div{
  height:50px;
  width:47.5px;
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;
  background:transparent;
  border:none;
}

.main-nav-buttons{
  width:750px;
  height:100%;
  display:flex;
  justify-content: space-between;
  align-items:center;
}

.connect-wallet{
  width:214px;
  height:100%;
  background-color:transparent;
  border:2px solid #FFB240;
  outline:none;
  color:#FFB240;
  border-radius:100px;
  font-family:Karla;
  font-size:20px;
  transition:0.5s all ease;
}

.connect-wallet:hover{
  border:2px solid white;
  color:white;
  background-color:black;
}


.main-nav-btn{
  color:white;
  background-color:transparent;
  border:none;
  font-family:Karla;
  font-size:20px;
  text-decoration:none;
}

.main-nav-btn:hover{
  background: linear-gradient(180deg, #FFB240 -17.39%, #FF6A4F 132.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 3px rgba(255, 106, 79, 0.3);
}

@keyframes rainbow-text {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.main-nav-rainbow {
  background: linear-gradient(45deg, #FFB240, #FF6A4F, #FD504A, #FA5B75, #F767A1, #D665D1, #A363F6, #6A61F8, #FFB240);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent; /* Makes the text color transparent */
  -webkit-animation: rainbow-text 8s ease infinite;
  -moz-animation: rainbow-text 8s ease infinite;
  animation: rainbow-text 8s ease infinite;
  border:none;
  font-family:Karla;
  font-size:20px;
  text-decoration:none;
}

.main-nav-rainbow:hover{
  background: linear-gradient(180deg, #FFB240 -17.39%, #FF6A4F 132.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 3px rgba(255, 106, 79, 0.3);
}

.note {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(45deg, rgba(255, 178, 64, 0.5), rgba(255, 106, 79, 0.5), rgba(253, 80, 74, 0.5), rgba(250, 91, 117, 0.5), rgba(247, 103, 161, 0.5), rgba(214, 101, 209, 0.5), rgba(163, 99, 246, 0.5), rgba(106, 97, 248, 0.5));
  background-size: 400% 400%;
  -webkit-animation: rainbow-text 8s ease infinite;
  -moz-animation: rainbow-text 8s ease infinite;
  animation: rainbow-text 8s ease infinite;
  font-family: Karla;
  font-size: 20px;
  padding: 20px 0 8.5px;
  color: white;
}

.note a {
  color: inherit;
}

.note a:hover {
  color: #FFB240;
}

.presale-background{
  width:70vw;
  height:415px;
  position:absolute;
  left:-2%;
  bottom:0%;
  z-index:-99;
}

.presale-main-div{
  height:90vh;
  width:480px;
  border-radius:12px;
  color: white;
  background: linear-gradient(180deg, rgba(67, 48, 44, 0.4) 0%, rgba(48, 46, 60, 0.06) 100%);
  backdrop-filter: blur(5px);
  border:2px solid rgba(255,178,64,0.17);
  position:absolute;
  right:10%;
  top:13%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.presale-sub-div{
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  align-items: center;
  height:90%;
  width:386px
}

.presale-info{
  height:30%;
  width:408px;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.presale-stats{
  height:100%;
  width:197px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.presale-buttons{
  height:55%;
  width:364px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.presale-stat-header{
  font-size:20px;
  font-family: Karla;
  font-weight:bold;
  margin-bottom:2px;
  z-index: 9999999;
}

.presale-stat-info{
  display:flex;
  flex-direction: column;
  justify-content: center;
  font-size:18px;
  font-family:Karla;
  width:190px;
}

.presale-main-head{
  font-size:24px;
  font-family:Montserrat;
  font-weight: bold;
  position:relative;
  margin-bottom:30px;
}

.presale-button-div{
  height:48%;
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  margin:20px
}

.presale-button-head-extra{
  width:100%;
  font-size:15px;
  font-family:Karla;
  text-align: right;
}

.presale-button-head{
  font-weight:bold;
  font-size:18px;
  font-family:Karla;
  text-align: left;
}

.contribute-sub{
  font-size:14px;
  font-family:Karla;
  font-weight: lighter;
  color:rgba(255,255,255,0.7);
}

.presale-button{
  width:364px;
  height:30%;
  border-radius:50px;
  outline:none;
  border:none;
  color:white;
  font-family:Montserrat;
  font-weight:bold;
  background:linear-gradient(92.52deg, #FFB240 -1.91%, #FF6A4F 104.96%)
}

.presale-details{
  height: 207px;
  width:593px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  position:absolute;
  left:8%;
  top:30%;
}

.presale-details-head{
  font-size: 3.5vw;
  font-family:Bolder-Montserrat;
  text-shadow: 0px 0px 6px rgba(255,106,79,0.6);
  text-transform: uppercase;
	background: linear-gradient(to top, #FF4E6E 0%, #FFB240 50%);
  background-clip:none;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.presale-details-sub{
  font-size: 2.5vw;
  font-family:Bold-Montserrat;
  color:white;
}

.presale-details-info{
  font-size:20px;
  font-family:Karla;
  color:rgba(255,255,255,0.7);
  padding-top: 30px;
}

.presale-input{
  margin-right:5px;
  background:transparent;
  color:white;
  border:1px solid white;
  width:60px;
  border-radius: 10px;
}

.main-nav-selected{
  background: linear-gradient(180deg, #FFB240 -17.39%, #FF6A4F 132.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 3px rgba(255, 106, 79, 0.3);
  border-radius: 100px;
  height:52px;
  color:#FFB240;
  font-family:Karla;
  font-size:20px;
  border:none;
}

.market-page-div{
  height:190vh;
  width:100vw;
  overflow-x:hidden;
}

.market-info-div{
  position:relative;
  top: 5%;
  width:100vw;
  height: 190px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.market-info{
  width: 85%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:100%;
  border-radius:12px;
  border: 2px solid rgba(239, 71, 95, 0.1);
  background: linear-gradient(180deg, rgba(27, 27, 31, 0.5) 0%, rgba(35, 30, 36, 0.355) 100%);
}

.market-stat-box{
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height:100px;
  width:200px;
  margin:38px;
}

.market-stat{
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:18px;
  font-family:Karla;
  font-weight:bold;
  color:white;
  height:50%;
  width:100%;
  text-align:center;
  border-radius: 12px;
  background: rgba(41, 39, 45, 0.7);
}

.market-stat-head{
  color:transparent;
  font-family:Karla;
  font-size:20px;
  font-style: Bolder;
  background: linear-gradient(180deg, #FFB240 0%, #FF6A4F 100%);
  background-clip:none;
  -webkit-background-clip:text;
}

.coin-market-div{
  position:relative;
  top:10%;
  width:100vw;
  height:40vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

.coin-market-info{
  border-radius: 12px;
  border: 2px solid rgba(255, 106, 79, 0.1);
  background: linear-gradient(180deg, rgba(27, 27, 31, 0.5) 0%, rgba(35, 30, 36, 0.35) 100%);
  height:100%;
  width:85%;
  transition:0.75s all ease;
  color:white;
  margin:20px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
}

.coin-market-info:hover{
  border:2px solid white;
}

.gst-coin-div{
  width:95%;
  height:25%;
  background: rgba(41, 39, 45, 0.7);
  border-radius:12px;
  margin:10px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-family:Karla;
  font-size:18px;
}

.coin-market-sub{
  width: 87%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-family:Karla;
  font-size:18px;
  font-weight:bold;
  color:rgba(255, 255, 255, 0.7);
}

.market-pop-stats{
  width:85%;
  height:40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coin-market-img{
  width:95%;
  display:flex;
  justify-content: left;
  align-items: center;
  font-family:Karla;
  font-size:20px;
  font-weight:bold;
  margin:5px;
}

.gst-sizing{
  width:97%;
  height:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.market-pop-div{
  width:100vw;
  height:180vh;
  position:absolute;
  top:15%;
  display:flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index:100;
}

.market-pop{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  color:white;
  width:100%;
  height:50%;
}

.market-pop-tabs{
  display: flex;
  justify-content: center;
  align-items: center;
  width:70%;
  height:10%;
}

.market-tab{
  height:100%;
  width:50%;
  background-color:#1F1E20;
  border-top: 5px solid white;
  border-left: 2px solid #262429;
  border-right:2px solid #262429;
  border-bottom:none;
  color:white;
  font-family:Karla;
  font-size:24px;
  font-weight:bold;
}

.market-pop-body{
  width:70%;
  height:70%;
  background-color:#1F1E20;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.market-pop-pop{
  width:100%;
  height:100%;
  background-color: rgba(41, 39, 45, 1);
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius:12px;
}


.market-tab-not-selected{
  background-color:transparent;
  border:none;
  color:rgba(255,255,255,0.7);
  font-family:Karla;
  font-size:24px;
  font-weight:bold;
  height:100%;
  width:50%;
}

.market-transaction{
  display:flex;
  align-items:center;
  justify-content: space-between;
  width:90%;
  height:20%;
  background: rgba(41, 39, 45, 0.7);
  border-radius:12px;
  font-family:Karla;
}

.transaction-button{
  background-color: #302E3C;
  border:none;
  border-radius: 100px;
  color:white;
  height:40px;
  width:160px;
  font-family:Karla;
  font-size:14px;
  margin:10px;
}

.transaction-btns{
  width: 360px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.market-div-pop{
  width:100%;
  height:70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.transaction-div{
  width:80%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.transaction-input{
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-family: Karla;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  width: 40%;
  margin-top: 8px;
}

.market-stat-text{
  display:flex;
  justify-content: space-between;
  align-items: left;
  flex-direction:column;
  color:rgba(255,255,255,0.7);
  height: 100%;
  font-family:Karla;
  font-size:18px;
}

.market-statistics{
  width:100%;
  display:flex;
  justify-content: left;
  text-align: left;
  color:white;
  background:transparent;
}

.market-pop-stats-2{
  width:80%;
  height:48px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.input-pop{
  width: 35vw;
  height:40vh;
  position:absolute;
  top:40%;
  z-index:9999;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius:12px;
  border: 1px solid rgba(239, 71, 95, 0.1);
}

.market-confirm{
  width:80%;
  background:transparent;
  height:60%;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between;
  border-radius:12px;
}

.presale-input-div{
  position:absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index:1000;
  width:100%;
  height:30%;
  background:rgba(38,36,41,0.95);
  border-radius:12px;
  backdrop-filter:blur(14px);
}

.presale-input-sub{
  width:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-family:Karla;
  font-weight:Bold;
}

.presale-input-button{
  width:100%;
  margin:10px;
  height:30%;
  border-radius:50px;
  outline:none;
  border:none;
  color:white;
  font-family:Montserrat;
  font-weight:bold;
  background:linear-gradient(92.52deg, #FFB240 -1.91%, #FF6A4F 104.96%)
}

.presale-input-box{
  width:70%;
  height: 60%;
  display: flex;
  justify-content: space-between;
  flex-direction:column;
  align-items: center;
}

.presale-details-date{
  font-family:Karla;
  color:rgba(255, 255, 255, 0.7);
}

.presale-info-div{
  display:flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.market-info-image{
  height:50%;
  width:auto;
}

.market-info-stats{
  width:130px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family:Karla;
}

.table-div{
  height:113px;
  width:85%;
  background: rgba(41, 39, 45, 0.7);
  border-radius:10px;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
}

.earn-stake-div{
  width:80%;
  height:28%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


.market-coin-image{
  width: 120px;
  height:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.market-rewards-info{
  width:100px;
  justify-content: center;
  align-items: center;
  margin:30px;
}

.dashboard-div{
  width:100vw;
  height:110vh;
}

.earn-div{
  width:100vw;
  height:110vh;
}

.earn-main-div{
  width:100%;
  height:80%;
  display:flex;
  justify-content:center;
  align-items: center;
}

.earn-sub-div{
  width:80%;
  height:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.earn-input-div{
  height:95%;
  width:48%;
  border-radius:12px;
  border: 2px solid  rgba(255, 106, 79, 0.1);
  background:rgba(27, 27, 31, 0.5);
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.earn-input-header{
  color:white;
  font-family:Karla;
  font-weight: bold;
  font-size:20px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top:30px;
  margin-left:20px;
}

.earn-wallet-div{
  height:53px;
  width:80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.earn-wallet-text{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:white;
  font-family: Karla;
  font-size:14px;
}

.earn-claim-btn{
  border-radius:100px;
  background-color: #302E3C;
  border:none;
  display: flex;
  justify-content: center;
  align-items: center;
  width:150px;
  height:44px;
  color:white;
  font-family: Karla;
  font-size:17px;
  margin-bottom:26px;
}

.earn-popup{
  width: 558px;
  height:347px;
  display: flex;
  background: rgba(38, 36, 41, 1);
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.earn-popup-sub{
  height:70%;
  width:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.earn-popup-head{
  color:white;
  width:80%;
  text-align: left;
  font-family: Karla;
  font-size:24px;
  font-weight: bold;
}

.earn-popup-text{
  color:white;
  width:80%;
  display:flex;
  justify-content: space-between;
}

.earn-input{
  background-color:transparent;
  border:1px solid rgba(255, 255, 255, 0.3);
  font-family: Karla;
  color:white;
  font-size:18px;
  border-radius:8px;
  width:70%;
  margin-top:8px;
}

.earn-input-sub{
  display:flex;
  flex-direction:column; 
  justify-content:space-between; 
  align-content:center;
  color:rgba(255, 255, 255, 0.7);
  font-family:Karla;
  font-size:18px;
  height:55px;
  width:30%;
}

.earn-input-btn{
  background: rgba(48, 46, 60, 1);
  border-radius:100px;
  width:45%;
  color:white;
  border:none;
  height:100%;
}

.table-sub-div{
  width:85%;
  height:90%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.table-apr{
  height:50%;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.table-apr-head{
  width:100%;
  display:flex;
  justify-content: left;
  font-size:14px;
  font-family: Karla;
}

.table-apr-text{
  width:100%;
  display: flex;
  justify-content: left;
  font-family:Karla;
  color:rgba(255, 255, 255, 0.6);
}

.earn-text{
  position:relative;
  top:7%;
  left:10%;
  width:100%;
  height:130px;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  color:white;
  font-family:Karla;
  font-size:18px;
  margin-bottom:15px;
}
.gradient-text{
  text-shadow: 0px 0px 6px rgba(255,106,79,0.6);
  text-transform: uppercase;
	background: linear-gradient(to top, #FF4E6E 0%, #FFB240 50%);
  background-clip:none;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.market-input-buttons{
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:18%;
  width:55%;
}

.market-input-field{
  background:none;
  border:none;
  display:flex;
  justify-content: center;
  align-items: center;
  color:rgba(255, 255, 255, 0.6);
  border-radius:5px;
}

.market-input-field:hover{
  background: linear-gradient(180deg, #FFB240 -17.39%, #FF6A4F 132.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 3px rgba(255, 106, 79, 0.3);
}

.earn-info-image{
  height:50%;
  width:auto;
}

.market-pop-head{
  width:100%;
  color:white;
  font-family:Karla;
  font-weight: bold;
  font-size:20px;
}

.lending-div{
  width:100%;
  height:80%;
  display:flex;
  align-items: center;
  flex-direction: column;
}

.lending-text{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:55%;
  margin-left: 25px;
}

.lending-head{
  width:50%;
  height:20%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:34px;
}

.lending-stats{
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:70%;
  flex-direction: column;
  font-size:18px;
  font-family: Karla;
  font-weight: 500;
}

.lend-stat-head{
  font-size:18px;
  font-family:Karla;
  color:rgba(255,255,255,0.7)
}

.net-apr{
  position:absolute;
  background-color:rgba(31,30,32, 0.8);
  width:15%;
  height:90px;
  top:43%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color:white;
  z-index: 9999999;
  font-size:15px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  backdrop-filter: blur(5px);
}

.apr-stat{
  width:80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.triangle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}

.dashboard-main-div{
  margin-top:80px;
  width:100vw;
  height:230vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.dashboard-stats-div{
  width:80vw;
  height:40vh;
  font-family: Karla;
  color:white;
}

.dashboard-sub-div{
  width:100%;
  height:80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:15px;
}

.dashboard-stat-box{
  margin-top:10px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width:60%;
  height:90%;
  border-radius:12px;
  border: 2px solid rgba(239, 71, 95, 0.1);
  background: linear-gradient(180deg, rgba(27, 27, 31, 0.5) 0%, rgba(35, 30, 36, 0.355) 100%);
}

.dashboard-token-div{
  width:80vw;
  height:60vh;
  font-family: Karla;
  color:white;
}
.dashboard-lending-div{
  width:80vw;
  height:30vh;
  font-family: Karla;
  color:white;
}
.dashboard-index-div{
  width:80vw;
  height:75vh;
  font-family: Karla;
  color:white;
}

.dashboard-head{
  color:transparent;
  font-family:Bold-Karla;
  font-size:20px;
  font-style: Bolder;
  background: linear-gradient(180deg, #FFB240 0%, #FF6A4F 100%);
  background-clip:none;
  -webkit-background-clip:text;
}

.dashboard-stats{
  width:85%;
  height:50%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position:relative;
  margin-bottom:20px;
}

.dashboard-stats-head{
  position:relative;
  width:85%;
  font-family:Karla;
  font-weight: bold;
  font-size:20px;
  top:10%;
}

.dashboard-token-sub{
  width:100%;
  height:90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:15px;
}

.dashboard-token-box{
  width:55%;
  height:90%;
  border-radius:12px;
  border: 2px solid rgba(239, 71, 95, 0.1);
  background: linear-gradient(180deg, rgba(27, 27, 31, 0.5) 0%, rgba(35, 30, 36, 0.355) 100%);
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.dashboard-token-stats{
  width:85%;
  height:65%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position:relative;
  margin-bottom:20px;
}

.dashboard-market-box{
  margin-top:15px;
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:85%;
  border-radius:12px;
  border: 2px solid rgba(239, 71, 95, 0.1);
  background: linear-gradient(180deg, rgba(27, 27, 31, 0.5) 0%, rgba(35, 30, 36, 0.355) 100%);
}

.dashboard-market-head{
  color:white;
  font-family:Karla;
  font-size:20px;
  font-style: Bolder;
}

.dashboard-index-box{
  margin-top:15px;
  height:80%;
  width:100%;
  border-radius:12px;
  border: 2px solid rgba(239, 71, 95, 0.1);
  background: linear-gradient(180deg, rgba(27, 27, 31, 0.5) 0%, rgba(35, 30, 36, 0.355) 100%);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-index-coin{
  background: rgba(41, 39, 45, 0.7);
  border-radius:12px;
  font-family:Karla;
  width:95%;
  height:12.5%;
  margin:10px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.dashboard-index-sub{
  width:95%;
  height:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-index-headers{
  width:95%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat;
  color:rgba(255,255,255,0.7);
}

.dashboard-coin-stats{
  width:75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-stat{
  width:25%;
  display:flex;
  align-items: center;
  justify-content: center;
}


.dashboard-stat-head{
  position:relative;
  left:5%;
  width:20%;
  display: flex;
  align-items: center;
}

.dashboard-stat-coin-head{
  width:28%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.piechart-label{
  position:absolute;
  width:350px;
  height:350px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: Karla;
  font-size:20px;
  font-weight: bold;
  filter: drop-shadow(0px 0px 4px rgba(255, 178, 64, 0.6));
}


.piechart{
  margin-right: 100px;
  width:350px;
  height:350px;
}

.piechart-background{
  width:100%;
  height:100%;
}

/* dynamic sizing for smaller computers */
@media(max-width: 1300px){
  .statistics-div{
    height:100%;
    width:47.73%;
  }

  .spinning-item{
    width:50%;
    height:auto;
  }

  .statistic-body{
    font-size: 3.3vw;
  }

  .index-statistics{
    width: 79.44%;
  }

  .featured-div{
    width:79.375%;
  }

  .featured-gst{
    width:55.35%;
  }

  .featured-vault{
    width:42.17%;
  }
  
  .aspect-element-comp-yield{
    width: 38.75%;
  }

  .aspect-element-liquidation{
    width:32.29%;
  }

  .aspect-element-yield{
    width:23%;
  }

  .footer-icons{
    width:39.17%;
  }

  .eco-div{
    width: 75%;
  }

  .coin-stats-div-top{
    width:25%;
    height:51.37%
  }

  .coin-stats-div-bot{
    width:25%;
    height:51.37%
  }

  .coin-div{
    width:100%;
    height:19.01%;
  }

  .coin-text{
    font-size: 3.3vw;
  }

  .circle-div{
    width:45.07%;
    height:63.38%;
  }
  
  .index-text{
    font-size:1.39vw;
    width:57.15%;
  }

  .index-sub{
    font-size:2.22vw;
  }

  .index-header{
    font-size:5vw;
  }

  .statistic-head{
    font-size:1.66vw;
  }

  .aspect-text{
    font-size:1.66vw;
  }

  .featured-header-text{
    font-size:1.66vw;
  }

  .featured-text{
    font-size:1.39vw;
  }

  .index-btns{
    width:44.72%
  }

  .whitelist{
    width:27.95%;
    font-size:1.39vw;
  }

  .early-whitelist{
    width:34.16%;
    font-size:1.39vw;

  }

  .launchapp{
    width:48.54%;
    font-size:1.39vw;
  }

  .featured-head{
    font-size:4.17vw
  }

  .eco-title{
    font-size:4.17vw
  }

  .eco-info{
    font-size:2.08vw;
  }

  .index-buttons{
    width:28.61%
  }

  .whitelist-nav{
    width:43.69%;
    font-size:1.39vw;
  }

  .footer-logo{
    width:11.12%
  }

  .footer-twitter{
    width:6.71%;
  }

  .footer-git{
    width:5.95%;
  }

  .footer-medium{
    width:6.85%
  }

  .logo{
    width:3.30%
  }
}
/* mobile styling */
@media(max-width:650px){
  .white-logo-div{
    height:auto;
  }

  .index-div{
    overflow-x:hidden;
  }
  
  .animated-div{
    height:auto;
  }

  .animated-small-div{
    height:auto;
  }

  .circle-div{
    height:auto;
  }

  .spinning-item{
    display:none;
  }

  .index-nav{
    height:50px;
    display:flex;
    align-items:center;
  }

  .index-buttons{
    width: 45%;
  }

  .whitelist-nav{
    height: 70%;
    width:45%;
    font-size: 2.7vw;
  }

  .launchapp{
    height: 70%;
    width:50%;
    font-size: 2.7vw;
  }

  .logo{
    width:7%;
    left: 10%;
  }

  .index-text{
    height: 40%;
    top:15%;
    width:90%;
  }

  .index-header{
    font-size:6vw;
  }

  .index-sub{
    font-size: 4vw;
  }

  .index-body{
    font-size: 3.2vw;
  }

  .index-btns{
    width:75%;
  }

  .early-whitelist{
    height:85%;
    font-size:2.7vw;
  }

  .whitelist{
    height:85%;
    font-size:2.7vw;
  }

  .index-statistics{
    width: 90%;
    left:5%;
    height:15%;
    top:70%
  }

  .statistics-div{
    height:70%;
  }

  .statistic-head{
    font-size: 3vw;
    width:100%;
    top:-25%;
    left:-5%;
  }

  .statistic-body{
    font-size: 5vw;
    top:-35%;
  }

  .coin-stats-div-top{
    top:118%;
    width:35%;
    height:35%;
    left:5%;
  }

  .coin-stats-div-bot{
    top:243%;
    width:35%;
    height:35%;
    right:5%;
  }

  .coin-info{
    font-size:5.5vw;
    position:relative;
    top:5%;
  }

  .featured-div{
    top:300%;
    width:95%;
    left:2.5%;
  }

  .background-img{
    display:none;
  }

  .eco{
    top:340%;
  }

  .index-footer{
    top:380% !important;
  }

  .featured-head{
    font-size:6vw;
  }
  
  .featured-info{
    height:20%;
  }

  .featured-header-text{
    font-size: 2.2vw;
  }

  .featured-gst{
    width:65%;
  }

  .featured-text{
    font-size:1.8vw;
  }

  .featured-vault{
    width:50%;
  }

  .featured-aspect{
    display:none;
  }

  .eco-title{
    font-size: 6vw;
  }

  .eco-info{
    font-size: 4vw;
  }
  
  .eco-coins{
    height:20%;
  }
  
  .footer-icons{
    width:45%;
    height:20%;
  }

  .footer-logo{
    width:20%;
  }

  .presale-div{
    width:100vw;
  }

  .presale-details{
    width:80vw !important;
    height:20vh !important;
    top: 15% !important;
  }

  .presale-details-head{
    font-size:7vw !important;
  }
  .presale-details-sub{
    font-size: 5vw !important;
  }

  .presale-main-div{
    width:90vw !important;
    height:70vh !important;
    position:absolute !important;
    top:35% !important;
    left: 5% !important;
  }

  .index-footer{
    position:relative;
  }

  .presale-sub-div{
    height:90% !important;
    width: 80% !important;
  }

  .presale-info{
    width:100% !important;
    height:30% !important;
  }

  .presale-buttons{
    width:100% !important;
  }

  .presale-button-div{
    width:100% !important;
  }

  .presale-button{
    width:80% !important;
    height:30% !important;
    font-size: 3vw !important;
  }
  .presale-input-button{
    width:100% !important;
    height:25% !important;
    font-size: 3.5vw !important;
  }

  .presale-input-div{
    height:40% !important;
    font-size: 3.5vw !important;
  } 

  .presale-main-head{
    font-size: 5vw !important;
  }

  .main-navbar{
    height:10vh !important;
  }

  .main-nav-logo-div{
    height:0% !important;
    width: 0%!important;
    content:none !important;
    display:none;
  }

  .main-nav-buttons{
    width:60% !important; 
  }

  .presale-stat-header{
    font-size:4vw !important;
  }

  .presale-stat-info{
    font-size:3.5vw !important;
  }

  .main-nav-btn{
    font-size: 3.3vw !important;
  }
  .connect-wallet{
    width:32% !important;
    height:60% !important;
    font-size:3.3vw !important;
  }

  .presale-button-head{
    height:20% !important;
    font-size: 3.5vw !important;
  }

  .contribute-sub{
    font-size: 3vw !important;
  }

  .footer{
    height:7% !important;
  }

  .footer-git{
    width:15% !important;
  }

  .footer-twitter{
    width:15% !important;
  }

  .footer-medium{
    width:15% !important;
  }

  .footer-icons {
    width:50% !important;
    height: 100% !important;
  }

  .presale-input-box{
    width:80% !important;
  }

  .earn-text{
    font-size: 3vw !important;
    width:80% !important;
  }

  .earn-sub-div{
    flex-direction:column;
    width:90%;
  }

  .earn-input-div{
    width:100% !important;
    height:49% !important;
  }

  .earn-main-div{
    height: 100% !important;
    margin-top:10px !important;
  }

  .earn-input-header{
    font-size:3.5vw !important;
    height:30% !important;
  }

  .earn-claim-btn{
    font-size:3.3vw !important;
    height:55% !important;
    width:30% !important;
  }

  .earn-div{
    height:125vh !important;
  }

  .earn-wallet-text{
    font-size:2.7vw !important;
  }

  .table-apr-head{
    font-size:2.7vw !important;
  }

  .table-apr-text{
    font-size:2.5vw !important;
  }

  .table-div{
    height:17% !important;
  }
  
  .earn-popup{
    width:85% !important;
  }

  .earn-text{
    top:3% !important;
  }

  .earn-info-image{
    height:100% !important;
    width:auto !important;
  }
  
  .earn-stake-div{
    height:35% !important;
  }
  .market-stat-box{
    width:20%;
    margin:10px;
    height:50%;
  }
  .market-stat-head{
    font-size: 3vw !important;
  }
  .market-stat{
    font-size:3vw;
  }
  .market-info{
    height:70%;
    width:90%;
  }
  .main-nav-selected{
    font-size:3.3vw;
  }
  .coin-market-div{
    height:25vh;
    top:8%;
  }

  .coin-market-img{
    font-size:3.5vw;
  }
  
  .coin-market-sub{
    font-size:3.3vw;
  }

  .market-coin-image{
    font-size:3.3vw;
  }

  .market-page-div{
    height:150vh;
  }

  .market-info-stats{
    font-size:3.3vw;
  }

  .market-tab{
    font-size:3.5vw;
  }
  .market-tab-not-selected{
    font-size:3.5vw;
  }
  .market-stat-text{
    font-size:3.3vw;
  }

  .market-stat-text-solo{
    font-size:3.3vw;
  }

  .market-transaction{
    font-size:3.3vw;
  }

  .transaction-button{
    width:40%;
  }
  .transaction-btns{
    width:30%;
  }
  .market-pop{
    height:45%;
  }
  .market-pop-body{
    width:85%;
  }

  .market-pop-tabs{
    width:85%;
  }

  .market-pop-image{
    height:50%;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .lending-stats{
    font-size:3.5vw;
  }

  .lend-stat-head{
    font-size:4vw;
  }

  .lending-head{
    width:80%;
  }

  .gst-coin-div{
    margin-bottom:10px;
  }
}
/* PRESALE PAGE CSS */

